import React, { Fragment } from 'react'
import PostBlock from '../PostBlock'
import HeadlineAndBodyTextBlock from '../HeadlineAndBodyTextBlock'
import { logoType, getSlug, replaceStr, addSlashInternalLink } from '../../../helper'

const HeadlineBodyText = ({ headlineAndBodyText }) => {
    const headline = [{
        headlineValue: headlineAndBodyText.headline,
        headlineType: headlineAndBodyText.headlineType
    }]
    const body = [{
        bodyCopy: headlineAndBodyText.body
    }]
    return (
        <div className="col-lg-8">
            <HeadlineAndBodyTextBlock headlines={headline} body={body} button={headlineAndBodyText.button} showButton={headlineAndBodyText.showButton} />
        </div>
    )
}

const RelatedListenerBlock = ({ titleH3, description, relatedListeners, headlineAndBodyText }) => {
    const listenerSlug = getSlug(relatedListeners[0].uri)
    const listenerNameShortCode = [/\[listener_name_plural\]/g, /\[listener_name_singular\]/g]

    return (
        <div className="related-listener">
            <div className="related-wrapper">
                {titleH3 && (
                    <div className="headline">
                        <h3 dangerouslySetInnerHTML={{ __html: titleH3 }}></h3>
                    </div>
                )}
                {description && <div className="sub-headline" dangerouslySetInnerHTML={{ __html: addSlashInternalLink(description) }}></div>}
                <div>
                    <div className="row">
                        {
                            relatedListeners && relatedListeners.map((post, index) => {
                                const {
                                    listenerExcerpt,
                                    mainName,
                                    listenerNamePlural,
                                    listenerNameSingular,
                                    iconFileSvg,
                                    iconFileImage,
                                    iconUseImageInsteadOfSvg,
                                    slug
                                } = post.cfListener.mainElements
                                const logo = logoType(
                                    iconFileSvg,
                                    iconFileImage,
                                    iconUseImageInsteadOfSvg
                                )
                                const listenerName = [listenerNamePlural, listenerNameSingular]
                                let headlineBodyText = ''

                                if (index === 7 && headlineAndBodyText) {
                                    headlineBodyText = <HeadlineBodyText headlineAndBodyText={headlineAndBodyText} />
                                }

                                return (
                                    <Fragment key={index}>
                                        <div className="col-md-6">
                                            <PostBlock
                                                excerpt={replaceStr(listenerNameShortCode, listenerName, listenerExcerpt)}
                                                namePlural={mainName}
                                                logo={logo}
                                                postSlug={`/${listenerSlug}/${slug}`}
                                            />
                                        </div>
                                        {headlineBodyText && headlineBodyText}
                                    </Fragment>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RelatedListenerBlock
